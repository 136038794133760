@import '../../styles/variables.scss';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  height: 100%;
  overflow: hidden;
  position: relative;
}

body {
  box-sizing: border-box;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  line-height: 1.5rem;
  color: $color-light-grey;
  height: 100vh;
  position: relative;
}

#dateOfBirth + .MuiInputAdornment-root {
  display: none !important;
}

.adventureCardButton {
  color: #3276b5 !important;
  font-weight: 600 !important;
  width: 100%;
  font-size: 1rem !important;
  border-radius: 0 !important;

  &Content {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &Icon {
    float: right;
    height: 100%;
    display: flex;
    margin-top: 0.1rem;
  }

  &Text {
    text-align: center;
    height: 100%;
  }

  &TextDanger {
    text-align: center;
    height: 100%;
    color: #a30000;
  }

  &TextBright {
    text-align: center;
    height: 100%;
  }

  &TextDark {
    height: 100%;
    text-align: center;
    background-color: #eee;
    font-family: Roboto;
    font-style: italic;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0.0357143em;
    text-transform: uppercase;
    color: #878787;
    padding: 0.5rem;
  }
}

.rfu-file-upload-button {
  display: none !important;
}

#jitsiConferenceFrame0 {
  border-radius: 0.25rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
