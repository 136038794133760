// font size
$default-font-size: 1.6rem;

// color
$color-font-light: #fff;
$color-lightest-grey: #bababa;
$color-light-grey: #878787;
$color-medium-grey: #464646;
$color-dark-grey: #000;
$color-light-blue: #33a6d7;
$color-deep-blue: #3276b5;

// color-gradient
$color-gradient-blue: linear-gradient(
  104.37deg,
  $color-light-blue 7.55%,
  $color-deep-blue 95.18%
);
$color-gradient-grey: linear-gradient(
  104.37deg,
  $color-lightest-grey 7.55%,
  $color-light-grey 95.18%
);
