.select-panel {
  /* --rmsc-main: #4285f4; */
  /* --rmsc-hover: #f1f3f5; */
  --rmsc-selected: #e2e6ea;
  /* --rmsc-border: #ccc; */
  /* --rmsc-gray: #aaa; */
  /* --rmsc-bg: #fff; */
  --rmsc-p: 0.5rem; /* Spacing */
  /* --rmsc-radius: 4px; Radius */
  --rmsc-h: 2.375rem; /* Height */
}
